import type { ImgHTMLAttributes, ReactNode } from 'react'
import React, { forwardRef, useState } from 'react'

import BrokenImageDarkMode from '@core/assets/BrokenImageDarkMode.svg'
import BrokenImageLightMode from '@core/assets/BrokenImageLightMode.svg'

export enum ColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string
  mode?: ColorMode
  alt?: string
  role?: string
  fallbackComponent?: ReactNode | null
  // src is optional because in some Components we use just fallback mechanism
  src?: string
  imageRef?: React.Ref<HTMLImageElement>
}

/**
 *
 */
export const Image = forwardRef<HTMLImageElement, Props>(
  (
    {
      fallback,
      fallbackComponent = null,
      mode = ColorMode.DARK,
      alt,
      role,
      src,
      onError,
      ...restProps
    },
    ref,
  ) => {
    const [isFallback, setFallback] = useState(false)

    // we can't do truthy checks on alt, because an empty string is technically
    // a valid value
    const hasAlt = alt !== undefined

    if (!hasAlt && !role) {
      throw new Error(
        `Image requires one of alt text, or a role of either 'presentation' or 'none'`,
      )
    } else if (hasAlt && (role === 'presentation' || role === 'none')) {
      throw new Error(
        `Image cannot have both alt text and a role of 'presentation' or 'none'`,
      )
    } else if (!alt && role && role !== 'presentation' && role !== 'none') {
      throw new Error(
        `if Image alt is NOT set role MUST be either 'presentation' or 'none'`,
      )
    }
    const fallbackImageUrl =
      fallback ??
      (mode === ColorMode.DARK ? BrokenImageDarkMode : BrokenImageLightMode)

    return isFallback && fallbackComponent ? (
      <>{fallbackComponent}</>
    ) : (
      <img
        onError={(e) => {
          setFallback(true)
          onError?.(e)
        }}
        alt={alt}
        role={role}
        src={isFallback ? fallbackImageUrl : src}
        {...restProps}
        ref={ref}
      />
    )
  },
)
